import React, { useEffect, useState } from 'react'
import Winners from './Winners';
import { GET_CLUB_LEADERBOARD, getValuebet } from '../../../../../API-Handle/services/auth_curd';
import Switch from "react-switch";
import CustTooltip from '../../../../../Components/CustComp/CustTooltip';

export default function Leaderboard({ club }) {
    const [activeTab, setActiveTab] = useState(null);
    // To avoid showing loader multipule time using below status 
    const [dailyLeaderboard, setDailyLeaderboard] = useState(null);
    const [weeklyLeaderboard, setWeeklyLeaderboard] = useState(null);
    const [monthlyLeaderboard, setMonthlyLeaderboard] = useState(null);
    const [leaderboardType, setLeaderboardType] = useState("WINS"); // or NET

    const toggleLeaderboardType = () => {
        let leaderboard_type = 'WINS';
        if (leaderboardType === "WINS"){
            leaderboard_type = "NET"
        }
        setLeaderboardType(leaderboard_type)
    }

    const fetchWinners = async () => {
        let res = await getValuebet(`${GET_CLUB_LEADERBOARD + club.id}?report_type=${activeTab}&leaderboard_type=${leaderboardType}`)
        if (res) {
            if (activeTab === 'Daily') setDailyLeaderboard(res)
            if (activeTab === 'Weekly') setWeeklyLeaderboard(res)
            if (activeTab === 'Monthly') setMonthlyLeaderboard(res)
        }
    }

    useEffect(() => {
        if (activeTab) fetchWinners();
        // eslint-disable-next-line
    }, [activeTab, leaderboardType])

    useEffect(() => {
        let active_tab = club.leaderboard_status.daily ? "Daily" : club.leaderboard_status.weekly ? "Weekly" : club.leaderboard_status.monthly ? "Monthly" : null;
        setActiveTab(active_tab);
        // eslint-disable-next-line
    }, [club])

    const applyCust = club.leaderboard_status.daily && club.leaderboard_status.weekly && club.leaderboard_status.monthly;
    return (
        <>
            <div className="card text-start bg-vb-dark mb-0">
                <div className="card-header seperater">
                    <ul className="nav nav-tabs card-header-tabs">
                        {club.leaderboard_status.daily && <li className="nav-item">
                            <span className={`${applyCust && "leaderboard-tabs"} ${activeTab === 'Daily' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Daily')}>Daily</span>
                        </li>}
                        {club.leaderboard_status.weekly && <li className="nav-item">
                            <span className={`${applyCust && "leaderboard-tabs"} ${activeTab === 'Weekly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Weekly')}>Weekly</span>
                        </li>}
                        {club.leaderboard_status.monthly && <li className="nav-item">
                            <span className={`${applyCust && "leaderboard-tabs"} ${activeTab === 'Monthly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Monthly')}>Monthly</span>
                        </li>}
                        <li className='ms-1 d-flex justify-content-right align-items-center leaderboard-switch'>
                            <Switch
                                onChange={toggleLeaderboardType}
                                checked={leaderboardType === 'WINS'}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={22}
                                width={62}
                                onColor="#008000"
                                onHandleColor="#1e5e1efa"
                                offColor="#ffac8b"
                                offHandleColor="#e03f00"
                                borderRadius={8}
                                uncheckedIcon={
                                    <div className='d-flex justify-content-center align-items-center h-100 pe-2 text-dark font-size-11px'>
                                        NET
                                    </div>
                                }
                                checkedIcon={
                                    <div className='d-flex justify-content-center align-items-center h-100 ps-2 text-white font-size-11px'>
                                        WINS
                                    </div>
                                }
                                className="react-switch"
                            />
                            <CustTooltip 
                                id={club.id} 
                                text={"WINs only tracks winning days for Weekly/Monthly Leaderboard. NET tracks both winning and losing days."} 
                            />
                        </li>
                    </ul>
                </div>
                {activeTab === 'Daily' ?
                    <div className="card-body px-3 py-2 mt-1">
                        <Winners club={club} data={dailyLeaderboard} />
                    </div>
                    : activeTab === 'Weekly' ?
                        <div className="card-body px-3 py-2 mt-1">
                            <Winners club={club} data={weeklyLeaderboard} />
                        </div>
                        : activeTab === 'Monthly' ?
                            <div className="card-body px-3 py-2 mt-1">
                                <Winners club={club} data={monthlyLeaderboard} />
                            </div>
                            : null}
            </div>
        </>
    )
}